"use client"; // NavAccordionRoot has context

import { forwardRef } from "react";
import { Icon } from "./icon";
import {
  NavAccordionContent,
  NavAccordionHeader,
  NavAccordionIcon,
  NavAccordionItem,
  NavAccordionLink,
  NavAccordionRoot,
  NavAccordionTrigger,
  type NavAccordionRootProps,
  type NavAccordionRootRef,
} from "./nav-accordion.components";
import { findActiveRouteId, pathIsActive, type NavRoutes } from "./nav.utils";

// -----------------------------------------------------------------------------

export type NavAccordionRef = NavAccordionRootRef;

export interface NavAccordionProps extends NavAccordionRootProps {
  activePath: string;
  routes: NavRoutes;
}

const NavAccordion = forwardRef<NavAccordionRef, NavAccordionProps>(
  ({ className, activePath, routes, ...props }, ref) => {
    const defaultValue = findActiveRouteId(routes, activePath);
    return (
      <NavAccordionRoot {...props} defaultValue={defaultValue} ref={ref}>
        {routes.map((route) => {
          if (route.path) {
            return (
              <NavAccordionItem value={route.id} key={route.id}>
                <NavAccordionLink
                  activePathMatch={pathIsActive(route.path, activePath)}
                  href={route.path}
                >
                  <Icon name={route.iconName} />
                  <span>{route.element}</span>
                </NavAccordionLink>
              </NavAccordionItem>
            );
          }
          if (route.paths && route.paths.length > 0) {
            return (
              <NavAccordionItem value={route.id} key={route.id}>
                <NavAccordionHeader>
                  <NavAccordionTrigger
                    activePathMatch={route.id === defaultValue}
                  >
                    <Icon name={route.iconName} />
                    <span>{route.element}</span>
                    <NavAccordionIcon />
                  </NavAccordionTrigger>
                </NavAccordionHeader>
                <NavAccordionContent>
                  {route.paths.map((routeChild) => (
                    <NavAccordionLink
                      activePathMatch={pathIsActive(
                        routeChild.path,
                        activePath,
                      )}
                      href={routeChild.path}
                      indent={Boolean(route.iconName)}
                      size="sm"
                      key={routeChild.id}
                    >
                      {routeChild.element}
                    </NavAccordionLink>
                  ))}
                </NavAccordionContent>
              </NavAccordionItem>
            );
          }
          return null;
        })}
      </NavAccordionRoot>
    );
  },
);

NavAccordion.displayName = "NavAccordion";

export { NavAccordion };
