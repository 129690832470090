import {
  Content as RadixAccordionContent,
  Header as RadixAccordionHeader,
  Item as RadixAccordionItem,
  Root as RadixAccordionRoot,
  Trigger as RadixAccordionTrigger,
  type AccordionContentProps as RadixAccordionContentProps,
  type AccordionHeaderProps as RadixAccordionHeaderProps,
  type AccordionItemProps as RadixAccordionItemProps,
  type AccordionSingleProps as RadixAccordionSingleProps,
  type AccordionTriggerProps as RadixAccordionTriggerProps,
} from "@radix-ui/react-accordion";
import NextLink from "next/link";
import { forwardRef } from "react";
import { Icon, type IconProps, type IconRef } from "./icon";
import {
  navAccordionContentVariants,
  navAccordionHeaderVariants,
  navAccordionIconVariants,
  navAccordionItemVariants,
  navAccordionLinkVariants,
  navAccordionRootVariants,
  navAccordionTriggerVariants,
  type NavAccordionContentVariants,
  type NavAccordionHeaderVariants,
  type NavAccordionIconVariants,
  type NavAccordionItemVariants,
  type NavAccordionLinkVariants,
  type NavAccordionRootVariants,
  type NavAccordionTriggerVariants,
} from "./nav-accordion.styles";

// -----------------------------------------------------------------------------

export type NavAccordionRootRef = React.ElementRef<typeof RadixAccordionRoot>;

export interface NavAccordionRootProps
  extends Omit<RadixAccordionSingleProps, "type">,
    NavAccordionRootVariants {}

const NavAccordionRoot = forwardRef<NavAccordionRootRef, NavAccordionRootProps>(
  ({ className, ...props }, ref) => (
    <RadixAccordionRoot
      {...props}
      type="single"
      className={navAccordionRootVariants({ className })}
      ref={ref}
    />
  ),
);

NavAccordionRoot.displayName = "NavAccordionRoot";

export { NavAccordionRoot };

// -----------------------------------------------------------------------------

export type NavAccordionItemRef = React.ElementRef<typeof RadixAccordionItem>;

export interface NavAccordionItemProps
  extends RadixAccordionItemProps,
    NavAccordionItemVariants {}

const NavAccordionItem = forwardRef<NavAccordionItemRef, NavAccordionItemProps>(
  ({ className, ...props }, ref) => (
    <RadixAccordionItem
      {...props}
      className={navAccordionItemVariants({ className })}
      ref={ref}
    />
  ),
);

NavAccordionItem.displayName = "NavAccordionItem";

export { NavAccordionItem };

// -----------------------------------------------------------------------------

export type NavAccordionHeaderRef = React.ElementRef<
  typeof RadixAccordionHeader
>;

export interface NavAccordionHeaderProps
  extends Omit<RadixAccordionHeaderProps, "asChild">,
    NavAccordionHeaderVariants {}

const NavAccordionHeader = forwardRef<
  NavAccordionHeaderRef,
  NavAccordionHeaderProps
>(({ children, className, ...props }, ref) => {
  return (
    <RadixAccordionHeader
      {...props}
      asChild
      className={navAccordionHeaderVariants({ className })}
      ref={ref}
    >
      <div>{children}</div>
    </RadixAccordionHeader>
  );
});

NavAccordionHeader.displayName = "NavAccordionHeader";

export { NavAccordionHeader };

// -----------------------------------------------------------------------------

export type NavAccordionTriggerRef = React.ElementRef<
  typeof RadixAccordionTrigger
>;

export interface NavAccordionTriggerProps
  extends RadixAccordionTriggerProps,
    NavAccordionTriggerVariants {}

const NavAccordionTrigger = forwardRef<
  NavAccordionTriggerRef,
  NavAccordionTriggerProps
>(({ activePathMatch, className, ...props }, ref) => {
  return (
    <RadixAccordionTrigger
      {...props}
      data-active-path-match={activePathMatch}
      className={navAccordionTriggerVariants({ activePathMatch, className })}
      ref={ref}
    />
  );
});

NavAccordionTrigger.displayName = "NavAccordionTrigger";

export { NavAccordionTrigger };

// -----------------------------------------------------------------------------

export type NavAccordionIconRef = IconRef;

export interface NavAccordionIconProps
  extends Omit<IconProps, "name">,
    NavAccordionIconVariants {}

const NavAccordionIcon = forwardRef<NavAccordionIconRef, NavAccordionIconProps>(
  ({ className, ...props }, ref) => (
    <Icon
      {...props}
      name="ms-expand-more"
      className={navAccordionIconVariants({ className })}
      ref={ref}
    />
  ),
);

NavAccordionIcon.displayName = "NavAccordionIcon";

export { NavAccordionIcon };

// -----------------------------------------------------------------------------

export type NavAccordionContentRef = React.ElementRef<
  typeof RadixAccordionContent
>;

export interface NavAccordionContentProps
  extends RadixAccordionContentProps,
    NavAccordionContentVariants {}

const NavAccordionContent = forwardRef<
  NavAccordionContentRef,
  NavAccordionContentProps
>(({ children, className, ...props }, ref) => (
  <RadixAccordionContent
    {...props}
    className={navAccordionContentVariants({ className })}
    ref={ref}
  >
    <div>{children}</div>
  </RadixAccordionContent>
));

NavAccordionContent.displayName = "NavAccordionContent";

export { NavAccordionContent };

// -----------------------------------------------------------------------------

export type NavAccordionLinkRef = React.ElementRef<typeof NextLink>;

export interface NavAccordionLinkProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    NavAccordionLinkVariants {}

const NavAccordionLink = forwardRef<NavAccordionLinkRef, NavAccordionLinkProps>(
  ({ activePathMatch, className, href, indent, size, ...props }, ref) => {
    return (
      <NextLink
        {...props}
        href={href}
        data-active-path-match={activePathMatch}
        aria-current={activePathMatch ? "page" : undefined}
        className={navAccordionLinkVariants({
          activePathMatch,
          className,
          indent,
          size,
        })}
        ref={ref}
      />
    );
  },
);

NavAccordionLink.displayName = "NavAccordionLink";

export { NavAccordionLink };
