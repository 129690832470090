import {
  NavigationMenuContent as RadixNavigationMenuContent,
  NavigationMenuIndicator as RadixNavigationMenuIndicator,
  NavigationMenuItem as RadixNavigationMenuItem,
  NavigationMenuLink as RadixNavigationMenuLink,
  NavigationMenuList as RadixNavigationMenuList,
  NavigationMenu as RadixNavigationMenuRoot,
  NavigationMenuTrigger as RadixNavigationMenuTrigger,
  type NavigationMenuContentProps as RadixNavigationMenuContentProps,
  type NavigationMenuIndicatorProps as RadixNavigationMenuIndicatorProps,
  type NavigationMenuItemProps as RadixNavigationMenuItemProps,
  type NavigationMenuListProps as RadixNavigationMenuListProps,
  type NavigationMenuProps as RadixNavigationMenuRootProps,
  type NavigationMenuTriggerProps as RadixNavigationMenuTriggerProps,
} from "@radix-ui/react-navigation-menu";
import NextLink from "next/link";
import { forwardRef } from "react";
import { Icon } from "./icon";
import {
  navMenuContentLinkVariants,
  navMenuContentVariants,
  navMenuIndicatorVariants,
  navMenuItemVariants,
  navMenuLinkVariants,
  navMenuListVariants,
  navMenuPopoverArrowVariants,
  navMenuPopoverVariants,
  navMenuRootVariants,
  navMenuTriggerIconVariants,
  navMenuTriggerVariants,
  type NavMenuContentLinkVariants,
  type NavMenuContentVariants,
  type NavMenuIndicatorVariants,
  type NavMenuItemVariants,
  type NavMenuLinkVariants,
  type NavMenuListVariants,
  type NavMenuPopoverVariants,
  type NavMenuRootVariants,
  type NavMenuTriggerVariants,
} from "./nav-menu.styles";

// -----------------------------------------------------------------------------

export type NavMenuRootRef = React.ElementRef<typeof RadixNavigationMenuRoot>;

export interface NavMenuRootProps
  extends RadixNavigationMenuRootProps,
    NavMenuRootVariants {}

const NavMenuRoot = forwardRef<NavMenuRootRef, NavMenuRootProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixNavigationMenuRoot
        {...props}
        className={navMenuRootVariants({ className })}
        ref={ref}
      />
    );
  },
);

NavMenuRoot.displayName = "NavMenuRoot";

export { NavMenuRoot };

// -----------------------------------------------------------------------------

export type NavMenuListRef = React.ElementRef<typeof RadixNavigationMenuList>;

export interface NavMenuListProps
  extends RadixNavigationMenuListProps,
    NavMenuListVariants {}

const NavMenuList = forwardRef<NavMenuListRef, NavMenuListProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixNavigationMenuList
        {...props}
        className={navMenuListVariants({ className })}
        ref={ref}
      />
    );
  },
);

NavMenuList.displayName = "NavMenuList";

export { NavMenuList };

// -----------------------------------------------------------------------------

export type NavMenuItemRef = React.ElementRef<typeof RadixNavigationMenuItem>;

export interface NavMenuItemProps
  extends RadixNavigationMenuItemProps,
    NavMenuItemVariants {}

const NavMenuItem = forwardRef<NavMenuItemRef, NavMenuItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixNavigationMenuItem
        {...props}
        className={navMenuItemVariants({ className })}
        ref={ref}
      />
    );
  },
);

NavMenuItem.displayName = "NavMenuItem";

export { NavMenuItem };

// -----------------------------------------------------------------------------

export type NavMenuLinkRef = React.ElementRef<typeof NextLink>;

export interface NavMenuLinkProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    NavMenuLinkVariants {}

const NavMenuLink = forwardRef<NavMenuLinkRef, NavMenuLinkProps>(
  ({ activePathMatch, className, href, ...props }, ref) => {
    return (
      <RadixNavigationMenuLink active={activePathMatch} asChild>
        <NextLink
          {...props}
          href={href}
          data-active-path-match={activePathMatch}
          aria-current={activePathMatch ? "page" : undefined}
          className={navMenuLinkVariants({
            activePathMatch,
            className,
          })}
          ref={ref}
        />
      </RadixNavigationMenuLink>
    );
  },
);

NavMenuLink.displayName = "NavMenuLink";

export { NavMenuLink };

// -----------------------------------------------------------------------------

export type NavMenuTriggerRef = React.ElementRef<
  typeof RadixNavigationMenuTrigger
>;

export interface NavMenuTriggerProps
  extends RadixNavigationMenuTriggerProps,
    NavMenuTriggerVariants {}

const NavMenuTrigger = forwardRef<NavMenuTriggerRef, NavMenuTriggerProps>(
  ({ activePathMatch, children, className, ...props }, ref) => {
    return (
      <RadixNavigationMenuTrigger
        {...props}
        data-active-path-match={activePathMatch}
        className={navMenuTriggerVariants({ activePathMatch, className })}
        ref={ref}
      >
        <span>{children}</span>
        <Icon
          name="ms-arrow-drop-down"
          className={navMenuTriggerIconVariants()}
        />
      </RadixNavigationMenuTrigger>
    );
  },
);

NavMenuTrigger.displayName = "NavMenuTrigger";

export { NavMenuTrigger };

// -----------------------------------------------------------------------------

export type NavMenuContentRef = React.ElementRef<
  typeof RadixNavigationMenuContent
>;

export interface NavMenuContentProps
  extends RadixNavigationMenuContentProps,
    NavMenuContentVariants {}

const NavMenuContent = forwardRef<NavMenuContentRef, NavMenuContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixNavigationMenuContent
        {...props}
        className={navMenuContentVariants({ className })}
        ref={ref}
      />
    );
  },
);

NavMenuContent.displayName = "NavMenuContent";

export { NavMenuContent };

// -----------------------------------------------------------------------------

export type NavMenuIndicatorRef = React.ElementRef<
  typeof RadixNavigationMenuIndicator
>;

export interface NavMenuIndicatorProps
  extends RadixNavigationMenuIndicatorProps,
    NavMenuIndicatorVariants {}

const NavMenuIndicator = forwardRef<NavMenuIndicatorRef, NavMenuIndicatorProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadixNavigationMenuIndicator
        {...props}
        className={navMenuIndicatorVariants({ className })}
        ref={ref}
      >
        <div className={navMenuPopoverArrowVariants()} />
      </RadixNavigationMenuIndicator>
    );
  },
);

NavMenuIndicator.displayName = "NavMenuIndicator";

export { NavMenuIndicator };

// -----------------------------------------------------------------------------

export type NavMenuPopoverRef = React.ElementRef<"div">;

export interface NavMenuPopoverProps
  extends React.ComponentPropsWithoutRef<"div">,
    NavMenuPopoverVariants {}

const NavMenuPopover = forwardRef<NavMenuPopoverRef, NavMenuPopoverProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={navMenuPopoverVariants({ className })}
        ref={ref}
      />
    );
  },
);

NavMenuPopover.displayName = "NavMenuPopover";

export { NavMenuPopover };

// -----------------------------------------------------------------------------

export type NavMenuContentLinkRef = React.ElementRef<typeof NextLink>;

export interface NavMenuContentLinkProps
  extends React.ComponentPropsWithoutRef<typeof NextLink>,
    NavMenuContentLinkVariants {}

const NavMenuContentLink = forwardRef<
  NavMenuContentLinkRef,
  NavMenuContentLinkProps
>(({ activePathMatch, className, href, ...props }, ref) => {
  return (
    <RadixNavigationMenuLink active={activePathMatch} asChild>
      <NextLink
        {...props}
        href={href}
        data-active-path-match={activePathMatch}
        aria-current={activePathMatch ? "page" : undefined}
        className={navMenuContentLinkVariants({ activePathMatch, className })}
        ref={ref}
      />
    </RadixNavigationMenuLink>
  );
});

NavMenuContentLink.displayName = "NavMenuContentLink";

export { NavMenuContentLink };
