"use client";

import {
  Close as RadixDialogClose,
  Content as RadixDialogContent,
  Description as RadixDialogDescription,
  Overlay as RadixDialogOverlay,
  Portal as RadixDialogPortal,
  Dialog as RadixDialogRoot,
  Title as RadixDialogTitle,
  Trigger as RadixDialogTrigger,
  type DialogCloseProps as RadixDialogCloseProps,
  type DialogContentProps as RadixDialogContentProps,
  type DialogDescriptionProps as RadixDialogDescriptionProps,
  type DialogOverlayProps as RadixDialogOverlayProps,
  type DialogPortalProps as RadixDialogPortalProps,
  type DialogProps as RadixDialogRootProps,
  type DialogTitleProps as RadixDialogTitleProps,
  type DialogTriggerProps as RadixDialogTriggerProps,
} from "@radix-ui/react-dialog";
import { forwardRef } from "react";
import { Icon } from "./icon";
import {
  sheetCloseIconButtonVariants,
  sheetContentVariants,
  sheetDescriptionVariants,
  sheetFooterVariants,
  sheetHeaderVariants,
  sheetMainVariants,
  sheetOverlayVariants,
  sheetTitleVariants,
  type SheetCloseIconButtonVariants,
  type SheetContentVariants,
  type SheetDescriptionVariants,
  type SheetFooterVariants,
  type SheetHeaderVariants,
  type SheetMainVariants,
  type SheetOverlayVariants,
  type SheetTitleVariants,
} from "./sheet.styles";

// -----------------------------------------------------------------------------

export type SheetRef = React.ElementRef<typeof RadixDialogRoot>;

export interface SheetProps extends RadixDialogRootProps {}

export const Sheet = RadixDialogRoot;

// -----------------------------------------------------------------------------

export type SheetTriggerRef = React.ElementRef<typeof RadixDialogTrigger>;

export interface SheetTriggerProps extends RadixDialogTriggerProps {}

export const SheetTrigger = RadixDialogTrigger;

// -----------------------------------------------------------------------------

export type SheetPortalRef = React.ElementRef<typeof RadixDialogPortal>;

export interface SheetPortalProps extends RadixDialogPortalProps {}

export const SheetPortal = RadixDialogPortal;

// -----------------------------------------------------------------------------

export type SheetCloseRef = React.ElementRef<typeof RadixDialogClose>;

export interface SheetCloseProps extends RadixDialogCloseProps {}

export const SheetClose = RadixDialogClose;

// -----------------------------------------------------------------------------

export type SheetCloseIconButtonRef = React.ElementRef<typeof RadixDialogClose>;

export interface SheetCloseIconButtonProps
  extends Omit<RadixDialogCloseProps, "children">,
    SheetCloseIconButtonVariants {}

const SheetCloseIconButton = forwardRef<
  SheetCloseIconButtonRef,
  SheetCloseProps
>(({ className, ...props }, ref) => (
  <RadixDialogClose
    {...props}
    className={sheetCloseIconButtonVariants({ className })}
    ref={ref}
  >
    <Icon name="ms-close" />
    <span className="sr-only">Close</span>
  </RadixDialogClose>
));

SheetCloseIconButton.displayName = RadixDialogClose.displayName;

export { SheetCloseIconButton };

// -----------------------------------------------------------------------------

export type SheetOverlayRef = React.ElementRef<typeof RadixDialogOverlay>;

export interface SheetOverlayProps
  extends RadixDialogOverlayProps,
    SheetOverlayVariants {}

const SheetOverlay = forwardRef<SheetOverlayRef, SheetOverlayProps>(
  ({ className, ...props }, ref) => (
    <RadixDialogOverlay
      {...props}
      className={sheetOverlayVariants({ className })}
      ref={ref}
    />
  ),
);

SheetOverlay.displayName = RadixDialogOverlay.displayName;

export { SheetOverlay };

// -----------------------------------------------------------------------------

export type SheetContentRef = React.ElementRef<typeof RadixDialogContent>;

export interface SheetContentProps
  extends RadixDialogContentProps,
    SheetContentVariants {}

const SheetContent = forwardRef<SheetContentRef, SheetContentProps>(
  ({ className, side, ...props }, ref) => (
    <RadixDialogContent
      {...props}
      data-side={side}
      className={sheetContentVariants({ className, side })}
      ref={ref}
    />
  ),
);

SheetContent.displayName = RadixDialogContent.displayName;

export { SheetContent };

// -----------------------------------------------------------------------------

export type SheetHeaderRef = React.ElementRef<"div">;

export interface SheetHeaderProps
  extends React.ComponentPropsWithoutRef<"div">,
    SheetHeaderVariants {}

const SheetHeader = forwardRef<SheetHeaderRef, SheetHeaderProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={sheetHeaderVariants({ className })} ref={ref} />
  ),
);

SheetHeader.displayName = "SheetHeader";

export { SheetHeader };

// -----------------------------------------------------------------------------

export type SheetMainRef = React.ElementRef<"div">;

export interface SheetMainProps
  extends React.ComponentPropsWithoutRef<"div">,
    SheetMainVariants {}

const SheetMain = forwardRef<SheetMainRef, SheetMainProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={sheetMainVariants({ className })} ref={ref} />
  ),
);

SheetMain.displayName = "SheetMain";

export { SheetMain };

// -----------------------------------------------------------------------------

export type SheetFooterRef = React.ElementRef<"div">;

export interface SheetFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    SheetFooterVariants {}

const SheetFooter = forwardRef<SheetFooterRef, SheetFooterProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={sheetFooterVariants({ className })} ref={ref} />
  ),
);

SheetFooter.displayName = "SheetFooter";

export { SheetFooter };

// -----------------------------------------------------------------------------

export type SheetTitleRef = React.ElementRef<typeof RadixDialogTitle>;

export interface SheetTitleProps
  extends RadixDialogTitleProps,
    SheetTitleVariants {}

const SheetTitle = forwardRef<SheetTitleRef, SheetTitleProps>(
  ({ className, ...props }, ref) => (
    <RadixDialogTitle
      {...props}
      className={sheetTitleVariants({ className })}
      ref={ref}
    />
  ),
);

SheetTitle.displayName = RadixDialogTitle.displayName;

export { SheetTitle };

// -----------------------------------------------------------------------------

export type SheetDescriptionRef = React.ElementRef<
  typeof RadixDialogDescription
>;

export interface SheetDescriptionProps
  extends RadixDialogDescriptionProps,
    SheetDescriptionVariants {}

const SheetDescription = forwardRef<SheetDescriptionRef, SheetDescriptionProps>(
  ({ className, ...props }, ref) => (
    <RadixDialogDescription
      {...props}
      className={sheetDescriptionVariants({ className })}
      ref={ref}
    />
  ),
);

SheetDescription.displayName = RadixDialogDescription.displayName;

export { SheetDescription };
