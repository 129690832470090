import { compose, cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type NavMenuRootVariants = VariantProps<typeof navMenuRootVariants>;

export const navMenuRootVariants = cva({
  base: "w-fit",
});

// -----------------------------------------------------------------------------

export type NavMenuListVariants = VariantProps<typeof navMenuListVariants>;

export const navMenuListVariants = cva({
  base: "flex items-center gap-30px",
});

// -----------------------------------------------------------------------------

export type NavMenuItemVariants = VariantProps<typeof navMenuItemVariants>;

export const navMenuItemVariants = cva({
  base: "group/item",
});

// -----------------------------------------------------------------------------

export type NavMenuLinkVariants = VariantProps<typeof navMenuLinkVariants>;

export const navMenuLinkVariants = cva({
  base: [
    // layout
    "inline-flex h-36px items-center justify-center",
    // border
    "border-b-2",
    // background
    "bg-transparent",
    // foreground
    "text-sm leading-none",
    // animation
    "transition-colors",
    // focus/hover/active
    "hover:text-link-idle focus:text-link-idle active:text-link-idle",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
  ],
  variants: {
    activePathMatch: {
      true: [
        // border
        "border-b-link-idle",
        // foreground
        "text-link-idle",
      ],
      false: [
        // border
        "border-b-transparent",
        // foreground
        "text-shade-100",
      ],
    },
  },
  defaultVariants: {
    activePathMatch: false,
  },
});

// -----------------------------------------------------------------------------

export type NavMenuTriggerVariants = VariantProps<
  typeof navMenuTriggerVariants
>;

export const navMenuTriggerVariants = compose(
  navMenuLinkVariants,
  cva({
    base: [
      // layout group
      "group/trigger",
      // layout offset NavMenuTriggerIcon width, affects active underline
      "mr-18px",
    ],
  }),
);

// -----------------------------------------------------------------------------

export type NavMenuTriggerIconVariants = VariantProps<
  typeof navMenuTriggerIconVariants
>;

export const navMenuTriggerIconVariants = cva({
  base: [
    // layout
    "shrink-0",
    // layout offset icon width, affects active underline
    "-mr-18px",
    // animation
    "transition group-[[data-state=open]]/trigger:rotate-180",
  ],
});

// -----------------------------------------------------------------------------

export type NavMenuContentVariants = VariantProps<
  typeof navMenuContentVariants
>;

export const navMenuContentVariants = cva({
  base: [
    // layout match NavMenuIndicator height
    "pt-10px",
    // positioning
    "absolute top-full z-40",
    // positioning override
    "group-[[data-last=true]]/item:left-auto group-[[data-last=true]]/item:right-0",
    // animation in
    "data-[motion=from-end]:fade-in data-[motion=from-start]:fade-in",
    // animation out
    "data-[motion=to-end]:fade-out data-[motion=to-start]:fade-out",
  ],
});

// -----------------------------------------------------------------------------

export type NavMenuIndicatorVariants = VariantProps<
  typeof navMenuIndicatorVariants
>;

export const navMenuIndicatorVariants = cva({
  base: [
    // layout
    "flex items-end justify-center overflow-hidden",
    // layout match NavMenuContent padding-top
    "h-10px",
    // layout offset to overlap border
    "mt-1px",
    // position
    "top-full z-[41]",
    // cursor prevent NavMenuContent mouseout
    "pointer-events-none",
  ],
});

// -----------------------------------------------------------------------------

export type NavMenuPopoverArrowVariants = VariantProps<
  typeof navMenuPopoverArrowVariants
>;

export const navMenuPopoverArrowVariants = cva({
  base: [
    // layout
    "size-12px rotate-45",
    // position
    "relative top-[50%]",
    // radii/shadow
    "rounded-tl-sm shadow-md",
    // border
    "border-l border-t border-shade-15",
    // background
    "bg-white",
  ],
});

// -----------------------------------------------------------------------------

export type NavMenuPopoverVariants = VariantProps<
  typeof navMenuPopoverVariants
>;

export const navMenuPopoverVariants = cva({
  base: [
    // layout
    "py-8px",
    // radii/shadows
    "rounded-md shadow-md",
    // border
    "border border-shade-10",
    // background
    "bg-white",
    // foreground
    "text-shade-100",
  ],
});

// -----------------------------------------------------------------------------

export type NavMenuContentLinkVariants = VariantProps<
  typeof navMenuContentLinkVariants
>;

export const navMenuContentLinkVariants = cva({
  base: [
    // layout
    "flex min-w-max px-22px py-10px",
    // layout offset border
    "-mx-1px",
    // foreground
    "text-sm leading-tight",
    // animation
    "transition-colors",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
  ],
  variants: {
    activePathMatch: {
      true: [
        // background
        "bg-link-idle",
        // foreground
        "text-white",
      ],
      false: [
        // foreground
        "text-shade-100",
        // focus/hover/active background
        "hover:bg-shade-4 focus:bg-shade-4 active:bg-link-idle",
        // focus/hover/active foreground
        "active:text-white",
      ],
    },
  },
  defaultVariants: {
    activePathMatch: false,
  },
});
