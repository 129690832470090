import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type NavAccordionRootVariants = VariantProps<
  typeof navAccordionRootVariants
>;

export const navAccordionRootVariants = cva({
  base: "divide-y divide-black/10",
});

// -----------------------------------------------------------------------------

export type NavAccordionItemVariants = VariantProps<
  typeof navAccordionItemVariants
>;

export const navAccordionItemVariants = cva({});

// -----------------------------------------------------------------------------

export type NavAccordionHeaderVariants = VariantProps<
  typeof navAccordionHeaderVariants
>;

export const navAccordionHeaderVariants = cva({
  base: "flex",
});

// -----------------------------------------------------------------------------

export type NavAccordionTriggerVariants = VariantProps<
  typeof navAccordionTriggerVariants
>;

export const navAccordionTriggerVariants = cva({
  base: [
    // layout
    "flex flex-1 items-center gap-x-10px px-20px py-18px",
    "group/trigger",
    // border
    "border-x-4 border-transparent",
    // foreground
    "text-sm leading-none",
    // animation
    "transition-colors",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
  ],
  variants: {
    activePathMatch: {
      true: [
        // border
        "border-l-link-idle",
        // background
        "bg-primary-10",
        // foreground
        "text-link-idle",
      ],
      false: [
        // background
        "bg-white",
        // foreground
        "text-shade-100",
        // focus/hover/active border
        "hover:border-l-shade-8 focus-visible:border-l-shade-8",
        // focus/hover/active background
        "hover:bg-shade-2 focus-visible:bg-shade-2",
      ],
    },
  },
  defaultVariants: {
    activePathMatch: false,
  },
});

// -----------------------------------------------------------------------------

export type NavAccordionIconVariants = VariantProps<
  typeof navAccordionIconVariants
>;

export const navAccordionIconVariants = cva({
  base: [
    // layout
    "ml-auto shrink-0 translate-x-6px",
    // animation
    "transition-transform duration-200 group-[[data-state=open]]/trigger:rotate-180",
  ],
});

// -----------------------------------------------------------------------------

export type NavAccordionContentVariants = VariantProps<
  typeof navAccordionContentVariants
>;

export const navAccordionContentVariants = cva({
  base: [
    // layout (do NOT add padding here, add it to children, affects animations)
    "overflow-hidden",
    // animation
    "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
  ],
});

// -----------------------------------------------------------------------------

export type NavAccordionLinkVariants = VariantProps<
  typeof navAccordionLinkVariants
>;

export const navAccordionLinkVariants = cva({
  base: [
    // layout
    "flex items-center",
    // border
    "border-x-4 border-transparent",
    // foreground
    "text-sm leading-none",
    // animation
    "transition-colors",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
  ],
  variants: {
    activePathMatch: {
      true: [
        // border
        "border-l-link-idle",
        // background
        "bg-primary-10",
        // foreground
        "text-link-idle",
      ],
      false: [
        // background
        "bg-white",
        // foreground
        "text-shade-100",
        // focus/hover/active border
        "hover:border-l-shade-8 focus:border-l-shade-8",
        // focus/hover/active background
        "hover:bg-shade-2 focus:bg-shade-2",
      ],
    },
    indent: {
      true: "",
      false: "",
    },
    size: {
      sm: "py-8px pl-20px pr-50px",
      md: "gap-x-10px px-20px py-18px",
    },
  },
  compoundVariants: [
    {
      indent: true,
      size: "sm",
      className: "pl-48px",
    },
  ],
  defaultVariants: {
    activePathMatch: false,
    size: "md",
  },
});
