"use client"; // NavMenuRoot has context

import { forwardRef } from "react";
import {
  NavMenuContent,
  NavMenuContentLink,
  NavMenuIndicator,
  NavMenuItem,
  NavMenuLink,
  NavMenuList,
  NavMenuPopover,
  NavMenuRoot,
  NavMenuTrigger,
  type NavMenuRootProps,
  type NavMenuRootRef,
} from "./nav-menu.components";
import { findActiveRouteId, pathIsActive, type NavRoutes } from "./nav.utils";

export type NavMenuRef = NavMenuRootRef;

export interface NavMenuProps extends NavMenuRootProps {
  activePath: string;
  routes: NavRoutes;
}

const NavMenu = forwardRef<NavMenuRef, NavMenuProps>(
  ({ activePath, routes, ...props }, ref) => {
    const defaultValue = findActiveRouteId(routes, activePath);
    return (
      <NavMenuRoot {...props} defaultValue={defaultValue} ref={ref}>
        <NavMenuList>
          {routes.map((route, idx) => {
            if (route.path) {
              return (
                <NavMenuItem
                  data-last={routes.length === idx + 1 ? "true" : undefined}
                  key={route.id}
                >
                  <NavMenuLink
                    activePathMatch={pathIsActive(route.path, activePath)}
                    href={route.path}
                  >
                    {route.element}
                  </NavMenuLink>
                </NavMenuItem>
              );
            }
            if (route.paths && route.paths.length > 0) {
              return (
                <NavMenuItem
                  data-last={routes.length === idx + 1 ? "true" : undefined}
                  key={route.id}
                >
                  <NavMenuTrigger activePathMatch={route.id === defaultValue}>
                    {route.element}
                  </NavMenuTrigger>
                  <NavMenuContent>
                    <NavMenuPopover>
                      <ul>
                        {route.paths.map((routeChild) => (
                          <li key={routeChild.id}>
                            <NavMenuContentLink
                              activePathMatch={pathIsActive(
                                routeChild.path,
                                activePath,
                              )}
                              href={routeChild.path}
                            >
                              {routeChild.element}
                            </NavMenuContentLink>
                          </li>
                        ))}
                      </ul>
                    </NavMenuPopover>
                  </NavMenuContent>
                </NavMenuItem>
              );
            }
            return null;
          })}
          <NavMenuIndicator />
        </NavMenuList>
      </NavMenuRoot>
    );
  },
);

NavMenu.displayName = "NavMenu";

export { NavMenu };
