import { forwardRef } from "react";
import {
  BUTTON_VARIANTS_DEFAULTS,
  buttonCloseResponsiveVariants,
  buttonCloseVariants,
  buttonContentVariants,
  buttonIndicatorVariants,
  buttonSpinnerVariants,
  buttonVariants,
  buttonWithIndicatorVariants,
  type ButtonCloseResponsiveVariants,
  type ButtonCloseVariants,
  type ButtonVariants,
  type ButtonWithIndicatorVariants,
} from "./button.styles";
import { Icon } from "./icon";
import { Spinner } from "./spinner";

export type ButtonRef = React.ElementRef<"button">;

export interface ButtonProps
  extends React.ComponentPropsWithoutRef<"button">,
    ButtonVariants {}

const Button = forwardRef<ButtonRef, ButtonProps>(
  (
    {
      "aria-disabled": ariaDisabled,
      children,
      className,
      disabled = BUTTON_VARIANTS_DEFAULTS.disabled,
      iconOnly = BUTTON_VARIANTS_DEFAULTS.iconOnly,
      palette = BUTTON_VARIANTS_DEFAULTS.palette,
      pending = BUTTON_VARIANTS_DEFAULTS.pending,
      size = BUTTON_VARIANTS_DEFAULTS.size,
      underline = BUTTON_VARIANTS_DEFAULTS.underline,
      variant = BUTTON_VARIANTS_DEFAULTS.variant,
      type = "button",
      ...props
    },
    ref,
  ) => {
    return (
      <button
        {...props}
        type={type}
        aria-disabled={ariaDisabled ?? pending}
        disabled={disabled}
        className={buttonVariants({
          className,
          disabled,
          iconOnly,
          palette,
          pending,
          size,
          underline,
          variant,
        })}
        ref={ref}
      >
        {pending ? (
          <>
            <span
              data-content="children"
              className={buttonContentVariants({
                className: "invisible",
                iconOnly,
              })}
            >
              {children}
            </span>
            <span
              {...props}
              data-content="spinner"
              className={buttonSpinnerVariants({ className })}
              ref={ref}
            >
              <Spinner
                size="sm"
                palette={variant === "solid" ? "white" : palette}
              />
            </span>
          </>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = "Button";

export { Button };

// -----------------------------------------------------------------------------

export type ButtonWithIndicatorRef = React.ElementRef<"button">;

export interface ButtonWithIndicatorProps
  extends React.ComponentPropsWithoutRef<"button">,
    Omit<ButtonWithIndicatorVariants, "activeIndicator"> {
  count: number;
}

const ButtonWithIndicator = forwardRef<
  ButtonWithIndicatorRef,
  ButtonWithIndicatorProps
>(
  (
    {
      children,
      className,
      count = 0,
      disabled,
      iconOnly,
      palette,
      size,
      type = "button",
      underline,
      variant,
      ...props
    },
    ref,
  ) => {
    const active = count > 0;
    return (
      <button
        {...props}
        type={type}
        disabled={disabled}
        className={buttonWithIndicatorVariants({
          className,
          activeIndicator: active,
          iconOnly,
          palette,
          size,
          underline,
          variant,
        })}
        ref={ref}
      >
        {children}
        <span className={buttonIndicatorVariants({ active })}>
          <span className="sr-only">{count}</span>
        </span>
      </button>
    );
  },
);

ButtonWithIndicator.displayName = "ButtonWithIndicator";

export { ButtonWithIndicator };

// -----------------------------------------------------------------------------

export type ButtonCloseRef = React.ElementRef<"button">;

export interface ButtonCloseProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children">,
    ButtonCloseVariants {}

const ButtonClose = forwardRef<ButtonCloseRef, ButtonCloseProps>(
  ({ className, type = "button", ...props }, ref) => {
    return (
      <button
        {...props}
        type={type}
        className={buttonCloseVariants({ className })}
        ref={ref}
      >
        <Icon name="ms-close" />
        <span className="sr-only">Close</span>
      </button>
    );
  },
);

ButtonClose.displayName = "ButtonClose";

export { ButtonClose };

// -----------------------------------------------------------------------------

export type ButtonCloseResponsiveRef = React.ElementRef<"button">;

export interface ButtonCloseResponsiveProps
  extends React.ComponentPropsWithoutRef<"button">,
    ButtonCloseResponsiveVariants {}

const ButtonCloseResponsive = forwardRef<
  ButtonCloseResponsiveRef,
  ButtonCloseResponsiveProps
>(({ children, className, type = "button", ...props }, ref) => {
  return (
    <button
      {...props}
      type={type}
      className={buttonCloseResponsiveVariants({ className })}
      ref={ref}
    >
      <Icon name="ms-close" className="sm:hidden" />
      <span className="max-sm:sr-only">{children}</span>
    </button>
  );
});

ButtonCloseResponsive.displayName = "ButtonCloseResponsive";

export { ButtonCloseResponsive };
